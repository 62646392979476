import React, { FC } from 'react'

import { useWillsModalContext } from '@/contexts/WillsModalContext'
import { getPlanPrice } from '@/helpers/getPlansPrice'
import { CloudinaryImage } from 'ethos-design-system'
import { cmsModuleAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { mapQueryToUserData } from 'lib/@getethos/utils/mapQueryToUserData'
import { getQueryString } from 'lib/@getethos/utils/utils'
import { isEmpty } from 'lodash'

import { useWillsFunnelFlow } from '@/hooks/features/useWillsFunnelFlow'
import { useWillsPricing } from '@/hooks/features/useWillsPricing'
import { useNavigateToApp } from '@/hooks/useNavigateToApp'

interface HightlightWillsProps {
  clickthroughUrl?: string
}

const HightlightWills: FC<HightlightWillsProps> = ({ clickthroughUrl }) => {
  const { handleNavigateToApp } = useNavigateToApp()
  const { flow, isTreatment, isLeadForm } = useWillsFunnelFlow()
  const { setIsModalOpen } = useWillsModalContext()

  const queryParameters = getQueryString()
  const queryParamData = mapQueryToUserData(queryParameters || {})
  const userData = isEmpty(queryParamData) ? {} : queryParamData

  const { variation } = useWillsPricing()
  const { basicWillPlanPrice, fullEstatePlanPrice } = getPlanPrice(variation)

  const ctaData = {
    module: 'Highlight',
    ctaLabel: 'Get started',
    userData,
    clickthroughUrl: isTreatment || !clickthroughUrl ? '' : clickthroughUrl,
  }

  const handleCTAClick = () => {
    cmsModuleAnalytics.ctaClicked({
      properties: {
        module: ctaData.module,
        clickthroughUrl: ctaData.clickthroughUrl,
        ctaLabel: ctaData.ctaLabel,
        userData: ctaData.userData,
      },
      callback: null,
    })

    if (isLeadForm) {
      setIsModalOpen(true)
      return
    }

    handleNavigateToApp(
      ctaData.userData, //prefill user data
      false,
      false,
      false,
      ctaData.clickthroughUrl, // override CTA path to app with editor given URL,
      flow
    )
  }

  return (
    <div className="mx-auto">
      <div className="mx-auto max-w-[500px] lg:max-w-none">
        <div className="px-6 py-16 font-['Cambon'] text-[28px] font-bold tracking-[-0.28px] lg:max-w-none lg:px-8 lg:pb-16 lg:pt-[100px] lg:text-center lg:text-[48px] lg:tracking-normal">
          Protecting your family without the price of a lawyer.
        </div>
      </div>
      <div className="relative mb-8 w-full">
        <div className="absolute bottom-0 left-[-16px] h-[226px] w-[62%] min-w-[350px] rounded-2xl bg-[linear-gradient(135deg,_#FEF0E5_-33.31%,_#EBF0F9_121.7%)] lg:h-[83%] lg:w-1/2 lg:max-w-none" />
        <div className="mx-auto max-w-[500px] lg:max-w-none">
          <div className="relative z-1 mx-auto grid max-w-[1440px] items-end gap-x-16 lg:grid-cols-2">
            <div className="order-2 flex h-[255px] w-full max-w-[350px] items-center justify-center lg:order-none lg:ml-auto lg:h-full lg:max-h-[600px] lg:min-h-[450px] lg:max-w-[570px]">
              <CloudinaryImage
                className="h-full max-h-[255px] lg:max-h-none"
                publicId="https://res.cloudinary.com/getethos/image/upload/v1718412358/AdobeStock_541176077_cutout_ifmjsw.png"
                alt="Family"
                crop={CloudinaryImage.CROP_METHODS.FIT}
                height={[268, 268, 600, 600]}
                width={[255, 255, 570, 570]}
              />
            </div>
            <div className="flex w-auto flex-col justify-center px-6 lg:h-[83%] lg:px-0">
              <div className="mb-6 font-bold uppercase tracking-[1.28px] text-[#056257] lg:mb-8 lg:text-[24px] lg:tracking-[1.92px]">
                100% Achievable TODAY
              </div>
              <div className="mb-8 text-[28px] font-medium tracking-[-0.28px] lg:mb-12 lg:text-[40px] lg:tracking-[-0.4px]">
                Create a will for ${basicWillPlanPrice}
              </div>
              <ul className="m-0 mb-6 flex flex-col gap-y-6 pl-7 text-[18px] leading-[26px] tracking-[-0.28px] lg:mb-0 lg:gap-y-8 lg:pl-10 lg:text-[28px] lg:[&_li]:pl-3">
                <li>Have a plan for your kids and your assets</li>
                <li>Prevent drawn out court proceedings</li>
                <li>Bring peace of mind to your family</li>
                <li>Make your wishes clear in 20 minutes</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto mb-16 flex w-full max-w-[1440px] lg:mb-[100px] lg:justify-end lg:pl-20">
        <div className="ml-auto w-[70%] min-w-[450px] rounded-bl-[90px] rounded-tl-2xl bg-[linear-gradient(90deg,_#DEEBFF_-28.24%,_#C0D8D5_187.99%)] px-6 pb-[110px] pt-10 max-[450px]:w-full max-[450px]:min-w-0 lg:w-full lg:min-w-0 lg:rounded-bl-[150px] lg:rounded-br-2xl lg:rounded-tl-2xl lg:rounded-tr-[150px] lg:pb-[175px] lg:pl-[100px] lg:pt-20">
          <div className="max-w-[500px] lg:max-w-none">
            <div className="mb-6 font-bold uppercase tracking-[1.28px] text-[#056257] lg:mb-8 lg:text-[24px] lg:tracking-[1.92px]">
              For Even More Protection
            </div>
            <div className="mb-8 text-[28px] font-medium tracking-[-0.28px] lg:mb-12 lg:text-[40px] lg:tracking-[-0.4px]">
              Create a will, trust and more for ${fullEstatePlanPrice}
            </div>
            <ul className="m-0 mb-6 flex flex-col gap-y-6 pl-7 text-[18px] leading-[26px] tracking-[-0.28px] lg:mb-0 lg:gap-y-8 lg:pl-10 lg:text-[28px] lg:[&_li]:pl-3">
              <li>Help your family avoid probate court</li>
              <li>Avoid unnecessary taxes</li>
              <li>
                Assign who can make decisions for you with powers of attorney
              </li>
              <li>Share health care directives to inform future care</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-[500px] lg:max-w-none">
        <div className="mb-16 px-6 font-['Cambon'] text-[28px] font-bold tracking-[-0.28px] lg:mb-20 lg:max-w-none lg:px-8 lg:text-center lg:text-[48px] lg:tracking-normal">
          Something this important should be easily done
        </div>
        <div className="relative mb-20 w-full lg:mb-[120px]">
          <div className="mx-auto grid max-w-[1440px] gap-x-16 lg:grid-cols-2 lg:px-8">
            <div className="mb-12 flex max-w-[514px] flex-col justify-center px-6 lg:mb-0 lg:ml-auto lg:px-0">
              <div className="mb-6 text-[28px] font-medium tracking-[-0.28px] lg:mb-8 lg:text-[40px] lg:tracking-[-0.4px]">
                Vetted by attorneys
              </div>
              <div className="mb-12 text-lg tracking-[-0.28px] lg:text-[28px] lg:leading-[40px] ">
                All our documents are crafted by leading estate-planning
                attorneys with decades of experience.
              </div>
              <button
                className="w-full max-w-[327px] cursor-pointer rounded-lg bg-[#F49640] px-5 py-5 text-2xl font-medium leading-none tracking-[-0.24px] text-neutral-800 shadow hover:brightness-105"
                onClick={handleCTAClick}
              >
                Get started
              </button>
            </div>
            <div className="ml-auto flex h-[300px] w-full justify-start px-6 lg:h-full lg:max-w-none lg:px-0">
              <CloudinaryImage
                className="h-full max-h-[300px] rounded-xl lg:max-h-none"
                publicId="https://res.cloudinary.com/getethos/image/upload/v1718668184/31240cec25798b8bfe74b08043373c83_feeybg.jpg"
                alt="Family"
                crop={CloudinaryImage.CROP_METHODS.FIT}
                height={[268, 268, 600, 600]}
                width={[255, 255, 700, 700]}
              />
            </div>
          </div>
        </div>
        <div className="relative mb-20 w-full lg:mb-[160px]">
          <div className="mx-auto flex max-w-[1440px] flex-col justify-around lg:flex-row lg:gap-x-8 lg:px-8">
            <div className="order-none lg:order-1">
              <div className="mb-12 flex h-full max-w-[514px] flex-col justify-center px-6 lg:mb-0 lg:px-0">
                <div className="mb-6 text-[28px] font-medium tracking-[-0.28px] lg:mb-8 lg:text-[40px] lg:tracking-[-0.4px]">
                  Save time, save money
                </div>
                <div className="text-lg tracking-[-0.28px] lg:text-[28px] lg:leading-[40px] ">
                  With a will for just $99 that includes unlimited edits
                  forever, we can save you thousands compared to the cost of an
                  attorney.
                </div>
              </div>
            </div>
            <div className="">
              <div className="mx-auto w-[90%] max-w-[480px] px-6 lg:h-full lg:w-full lg:px-0">
                <CloudinaryImage
                  className="h-full"
                  publicId="https://res.cloudinary.com/getethos/image/upload/v1718675075/14ccbd0d739d2d1b57e09b1b4aff2b74_qhkh2m.png"
                  alt="Family"
                  crop={CloudinaryImage.CROP_METHODS.FIT}
                  height={[268, 268, 600, 600]}
                  width={[255, 255, 700, 700]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full">
          <div className="mx-auto flex max-w-[1440px] flex-col justify-around lg:flex-row lg:gap-x-8 lg:px-8">
            <div>
              <div className="flex h-full max-w-[514px] flex-col justify-center px-6 lg:px-0">
                <div className="mb-6 text-[28px] font-medium tracking-[-0.28px] lg:mb-8 lg:text-[40px] lg:tracking-[-0.4px]">
                  Easier for everyone
                </div>
                <div className="text-lg tracking-[-0.28px] lg:text-[28px] lg:leading-[40px] ">
                  Our simple step-by-step process lets you go at your own pace.
                  Most people complete everything in about 20 minutes.
                </div>
              </div>
            </div>
            <div className="hidden lg:block">
              <div className="mx-auto max-w-[509px] px-6 lg:h-full lg:px-0">
                <CloudinaryImage
                  className="h-full"
                  publicId="https://res.cloudinary.com/getethos/image/upload/v1718674056/wills-phone-app_gdsaog.png"
                  alt="Family"
                  crop={CloudinaryImage.CROP_METHODS.FIT}
                  height={[0, 0, 552, 552]}
                  width={[0, 0, 509, 509]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HightlightWills
