import { WillsAndTrustPricingVariants } from 'lib/@getethos/experiments/constants'

export const getPlanPrice = (variant: WillsAndTrustPricingVariants) => {
  if (variant === WillsAndTrustPricingVariants.TREATMENT_1) {
    return {
      basicWillPlanPrice: 129,
      fullEstatePlanPrice: 299,
    }
  }
  if (variant === WillsAndTrustPricingVariants.TREATMENT_2) {
    return {
      basicWillPlanPrice: 69,
      fullEstatePlanPrice: 149,
    }
  }

  return {
    basicWillPlanPrice: 99,
    fullEstatePlanPrice: 199,
  }
}
